import React from 'react'

import { About } from 'components/About'
import { Layout } from 'components/Layout'
import { Profile } from 'components/Profile'

const AboutPage = () => (
  <Layout>
    <Profile />
    <About />
  </Layout>
)

export default AboutPage
