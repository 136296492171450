import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'

import styles from 'assets/styles/global.module.css'
import css from './PostItem.module.css'

const PostItem = ({ path, title, date }) => (
  <li className={styles.list}>
    <Link to={path}>
      <h2 className={css.title}>{title}</h2>
      <small className={css.date}>{date}</small>
    </Link>
  </li>
)

PostItem.propTypes = {
  path: PropTypes.string,
  title: PropTypes.string,
  date: PropTypes.string,
}

export {
  PostItem,
}
