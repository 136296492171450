import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import css from './Header.module.css'

const Header = ({ title }) => (
  <header className={css.container}>
    <nav className={`${css.item} ${css.title}`}>
      <Link className={css.link} to="/">
        {title.split(' ').map(string => (
          <span className={css.title__segment} key={`title-${string}`}>{string}</span>
        ))}
      </Link>
    </nav>
  </header>
)

Header.propTypes = {
  title: PropTypes.string,
}

export {
  Header,
}
