import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'

import { Layout } from 'components/Layout'

import css from './PostTemplate.module.css'

/* eslint-disable react/no-danger */
const PostTemplate = ({ data }) => {
  const { markdownRemark: post } = data
  const { title, date, image, description } = post.frontmatter

  return (
    <Layout>
      <Helmet
        title={`${data.site.siteMetadata.title} | ${title}`}
        meta={[
          { name: 'description', content: description },
          { property: 'og:title', content: title },
          { property: 'og:description', content: description },
          { property: 'og:type', content: 'article' },
          { property: 'og:image', content: `/assets/meta/${image}` },
        ]}
      />
      <div>
        <div className={css.frontmatter}>
          <h1 className={css.title}>{title}</h1>
          <p className={css.date}>{date}</p>
        </div>
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
      </div>
    </Layout>
  )
}

PostTemplate.propTypes = {
  data: PropTypes.object,
}

export const postQuery = graphql`
  query BlogPostByPath($path: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark (
      frontmatter: {
        path: {
          eq: $path
        }
      }
    ) {
      html
      frontmatter {
        date(formatString: "Do MMM YYYY")
        path
        title
        image
        description
      }
    }
  }
`

export default PostTemplate
