import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import css from './Profile.module.css'

const Profile = ({ site }) => {
  const { siteMetadata } = site
  const {
    name,
    avatar,
    location,
    subtitle,
    description,
  } = siteMetadata

  return (
    <div className={css.container}>
      <div className={css.details}>
        <div>
          <h1 className={css.name}>{name}</h1>
          <h2 className={css.title}>{subtitle}</h2>
          <p className={css.location}>{location}</p>
        </div>
        <img
          className={css.portrait}
          src={require(`assets/images/${avatar}`)}
          alt="Mark Gono"
          width={150}
          height={150}
        />
      </div>
      <blockquote className={css.description}>
        {description}
      </blockquote>
    </div>
  )
}

Profile.propTypes = {
  site: PropTypes.object,
}

const ProfileWithQuery = () => (
  <StaticQuery
    query={graphql`
      query ProfileQuery {
        site {
          siteMetadata {
            name
            subtitle
            avatar
            description
            location
          }
        }
      }
    `}
    render={Profile}
  />
)

export {
  ProfileWithQuery as Profile,
}
