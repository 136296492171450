import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import styles from 'assets/styles/global.module.css'

import favicon from 'assets/favicon.png'

import './base.css'
import { Header } from 'components/Header'
import { Footer } from 'components/Footer'
import grid from './grid.module.css'


const withChildren = (Component, children) => (
  data => <Component data={data}>{children}</Component>
)

const Layout = ({ children, data }) => (
  <div>
    <Helmet
      title={data.site.siteMetadata.title}
    >
      <html lang="en" />
      <link rel="icon" type="image/png" href={favicon} />
      <meta name="description" content={data.site.siteMetadata.metaDesc} />
    </Helmet>
    <div className={grid.header}>
      <Header
        title={data.site.siteMetadata.name}
      />
    </div>
    <div className={grid.grid}>
      <div className={`${grid.main} ${styles.main}`}>
        {children}
      </div>
      <div className={grid.footer}>
        <Footer />
      </div>
    </div>
  </div>
)

Layout.propTypes = {
  children: PropTypes.node,
  data: PropTypes.object,
}

const query = graphql`
  query SiteTitleQuery {
    site {
      siteMetadata {
        title
        name
        metaDesc
      }
    }
  }
`

const LayoutWithQuery = ({ children }) => (
  <StaticQuery
    query={query}
    render={withChildren(Layout, children)}
  />
)

LayoutWithQuery.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}

export {
  LayoutWithQuery as Layout,
}
