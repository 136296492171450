import React from 'react'

import css from './Footer.module.css'

const Footer = () => (
  <footer className={css.container}>
    <p className={css.intro}>
      Find me under <span className={css.handle}>@markgono</span> on
    </p>
    <ul className={css.list}>
      <li className={css.item}><a href="https://github.com/markgono">Github</a></li>
      <li className={css.item}><a href="https://linkedin.com/in/markgono">LinkedIn</a></li>
      <li className={css.item}><a href="http://twitter.com/markgono">Twitter</a></li>
    </ul>
  </footer>
)

export {
  Footer,
}
