import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { Layout } from 'components/Layout'
import { Profile } from 'components/Profile'
import { PostItem } from 'components/PostItem'

const getPosts = data => data.allMarkdownRemark.edges.map(edge => edge.node)

const HomePage = ({ data }) => (
  <Layout>
    <Profile />
    <ul>
      {getPosts(data)
        .map(post => (
          <PostItem
            key={post.id}
            path={post.frontmatter.path}
            title={post.frontmatter.title}
            date={post.frontmatter.date}
          />
        ))
      }
    </ul>
  </Layout>
)

HomePage.propTypes = {
  data: PropTypes.object,
}

export const indexQuery = graphql`
  query IndexBlogPosts {
      allMarkdownRemark(
      sort: {
        order: DESC
        fields: [
          frontmatter___date
        ]
      }
      filter: {
          frontmatter: { 
            published: {
              eq: true
            }
          }
        }
      limit: 10
    ) {
      edges {
        node {
          id
          frontmatter {
            path
            date(formatString: "Do MMM YYYY")
            title
            published
          }
        }
      }
    }
  }
`

export default HomePage
