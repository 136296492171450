import React from 'react'

import css from './About.module.css'

const About = () => (
  <div>
    <p className={css.location}>&#128205; London</p>
    <ul>
      <li>Formerly engineering web applications @ <a href="http://buzzfeed.com">BuzzFeed</a></li>
      <li>
        <p>Previously worked on:</p> 
        <p>The web Menu Interface and User Aquisition at <a href="http://gousto.co.uk">Gousto</a></p>
        <a href="http://shopify.com">Shopify Plus</a> stores @ <a href="http://strawberry.co.uk">Strawberry</a>
      </li>
      <li>Studied Computer Science at the University of Hull, where:</li>
      <li>I interned in Research and demonstrated Programming Labs</li>
      <li>I love Politics, Space, Computer Hardware and Interactive Media</li>
    </ul>
  </div>
)

export {
  About,
}
