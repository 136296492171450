import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'

import { Layout } from 'components/Layout'
import { PostItem } from 'components/PostItem'

const BlogTemplate = ({ data }) => {
  const posts = data.allMarkdownRemark.edges.map(edge => edge.node)

  return (
    <Layout>
      <ul>
        {posts
          .map(post => (
            <PostItem
              key={post.id}
              path={post.frontmatter.path}
              title={post.frontmatter.title}
              date={post.frontmatter.date}
            />
          ))
        }
      </ul>
    </Layout>
  )
}

BlogTemplate.propTypes = {
  data: PropTypes.object,
}

export const blogQuery = graphql`
  query BlogPosts {
      allMarkdownRemark(
      sort: {
        order: DESC
        fields: [
          frontmatter___date
        ]
      }
      limit: 1000
      filter: {
        frontmatter: { 
          published: {
            eq: true
          }
        }
      }
    ) {
      edges {
        node {
          id
          frontmatter {
            path
            date
            title
            published
          }
        }
      }
    }
  }
`

export default BlogTemplate
